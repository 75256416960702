import regServiceWorker from "../../registerServiceWorker.js";
import notificationAgent from "./notificationAgent.js";

function getStatus() {
    if (!('serviceWorker' in navigator) || !Notification.permission)
        return null;

    // default, granted or denied 
    return Notification.permission;
}

function requestPermissionAndRegister(vapidPublicKey) {
    if (!vapidPublicKey) throw "No VAPID key";

    return Notification.requestPermission().then(result => {
                
        // default, granted or denied 
        if (result === "granted") {
            return getSubscriptionAndRegister(vapidPublicKey);
        }
    });
}

// TODO call this periodically so that we resubscribe / don't expire?
function getSubscriptionAndRegister(vapidPublicKey) {
    if (!vapidPublicKey) throw "No VAPID key";

    return new Promise((resolve, reject) => {

        regServiceWorker.then(reg => {

            if (!reg.pushManager) {
                reject("No pushManager");
                return;
            }
    
            reg.pushManager.getSubscription().then(sub => {
                if (sub === null) {
    
                    // Not subscribed
                    var config = {
                        userVisibleOnly: true,
                        applicationServerKey: vapidPublicKey
                    };
    
                    reg.pushManager.subscribe(config).then(function (sub) {
    
                        // Register new subscription with server
                        _register(sub).then(resolve);

                    }).catch(function (e) {
                        reject("Unable to subscribe to push: " + e);
                    });
    
                } else {
                    // Already subscribed - (re-)register with server
                    // TODO limit re-registration, e.g. every hour?
                    _register(sub).then(resolve);
                }
            });
        });
    });
}

function _register(sub) {

    var config = {
        endpoint: sub.endpoint,
        p256dh: _arrayBufferToBase64(sub.getKey("p256dh")),
        auth: _arrayBufferToBase64(sub.getKey("auth")),
    }

    return notificationAgent.registerForWebPush(config).then(() => {
        return config;
    });
}

function _arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export default 
{
    getStatus,
    requestPermissionAndRegister,
    getSubscriptionAndRegister,
};
