<template>
    <v-container>
        <VTopToolbar title="Notification settings">
            
            <!-- Override left nav button -->
            <template slot="left">
                <v-icon @click="saveAndBack()">
                    mdi-arrow-left
                </v-icon>
            </template>

        </VTopToolbar>

        <div v-show="loading">
            <v-skeleton-loader type="paragraph@3"></v-skeleton-loader>
        </div>

        <div v-show="!loading" class="mx-n3" v-bind:class="{ 'mt-6': !$vuetify.breakpoint.xs }">

            <v-alert
                dense
                text
                rounded="0"
                class="mt-n3"
            >Choose which notifications to receive</v-alert>

            <div
                v-for="category in categories"
                :key="category.name"
            >

                <v-list
                    subheader
                    two-line
                    flat
                >
                    <v-subheader>{{category.name}}</v-subheader>

                    <v-list-item-group multiple>
                        <v-list-item
                            v-for="setting in category.settings"
                            :key="setting.name"
                        >
                            <template>
                                <v-list-item-action>
                                    <v-checkbox
                                        v-model="setting.active"
                                        color="primary"
                                    ></v-checkbox>
                                </v-list-item-action>

                                <v-list-item-content>
                                    <v-list-item-title>{{setting.name}}</v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>

                <v-divider class="mb-2"></v-divider>

            </div>

        </div>
    </v-container>
</template>

<script>
import notificationAgent from "./notificationAgent.js";
import pushNotificationService from "./pushNotificationService.js";

// TODO move this to core - Components/Notifications/NotificationSettings

export default {
    data: function () {
        return {
            loading: true,
            categories: [],
            lastCategories: null,       // (stringified) Used for dirty check
            vapidPublicKey: null,
        };
    },

    mounted: function () {
        this.load();

        // Call fails if feature disabled on server
        notificationAgent.getWebPushConfig().then(c => {
            this.vapidPublicKey = c.vapidPublicKey;

            this.checkPushStatus();

        }).catch(console.error);
    },

    methods: {
        checkPushStatus() {

            var status = pushNotificationService.getStatus();
            
            if (status == null) {

                this.$coreUi.banner({
                    message: "Push notifications are not currently supported on your device.",
                    icon: "mdi-bell-off-outline",
                    multiline: true,
                });
            }
            else if (status === "default") {

                // Show banner to request notifications
                this.$coreUi.banner({
                    message: "Click to enable push notifications on your device.",
                    icon: "mdi-bell-ring-outline",
                    multiline: true,
                    btns: [
                        {
                            text: "Enable",
                            color: "success",
                            handler: () => {
                                pushNotificationService.requestPermissionAndRegister(this.vapidPublicKey)
                                    .catch(console.error);
                            }
                         }
                    ],
                });
            }
            else if (Notification.permission === "granted") {

                // Permission already granted - (re-)register for notifications
                pushNotificationService.getSubscriptionAndRegister(this.vapidPublicKey)
                    .catch(console.error);

                this.$coreUi.banner({
                    message: "Push notifications are enabled.",
                    icon: "mdi-bell-check-outline",
                    color: "success",
                });
            }
            else if (Notification.permission === "denied") {

                this.$coreUi.banner({
                    message: "You have blocked push notifications.",
                    icon: "mdi-bell-remove-outline",
                    color: "error",
                });
            }
            else
                console.warn(`Unknown notification permission ${status}`);
        },

        load() {
            notificationAgent.getSettings().then(cats => {
                this.loading = false;

                this.lastCategories = JSON.stringify(cats);
                this.categories = cats;

            }).catch(console.error);
        },

        saveAndBack() {
            // Dirty check
            var hasChanged = this.lastCategories !== JSON.stringify(this.categories);

            if (!this.loading && hasChanged) {
                var spinner = this.$coreUi.loading();

                notificationAgent.saveSettings(this.categories).then(() => {
                    spinner.dismiss();
                    
                    this.$router.back();

                }).catch(console.error);
            }
            else {
                this.$router.back();
            }
        },
    }
};
</script>
